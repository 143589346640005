<template>
  <div>
    <b-card>
    <b-row>
      <b-col cols="10">
        <user-profile-avatar :display_name="`${memberData.firstName} ${memberData.lastName}`" :source="memberData.avatar"/>
      </b-col>
      <b-col>
        <!--
        <b-button
          variant="outline-danger"
          class="ml-1 float-right"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          @click.prevent="deleteMember"
        >
          <span class="d-none d-sm-inline">Supprimer</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        -->
      </b-col>
    </b-row>
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">

          <b-form-group :label="$t('user_profile.first_name')" label-for="account-username">
            <b-form-input v-model="memberData.firstName" name="firstName" placeholder="John"/>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('user_profile.last_name')" label-for="account-username">
            <b-form-input v-model="memberData.lastName" name="lastName" placeholder="Doe"/>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group :label="$t('user_profile.email')" label-for="account-e-mail">
            <b-form-input v-model="memberData.email" name="email" placeholder="Email"/>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
            <b-form-group
              label="Role"
              label-for="user-role"
            >
              <v-select
                :options="roleOptions"
                label="label"
                :reduce="label => label.value"
                :clearable="false"
                input-id="user-role"
                v-model="memberData.role"
              />
            </b-form-group>
        </b-col>

        <b-col v-if="pcVoyagesEnabled" sm="6">
          <b-form-group :label="$t('user_profile.pcvoyages_agent_code')" label-for="agent-code">
            <b-form-input v-model="memberData.pcVoyagesAgentCode" name="pcVoyagesAgentCode" placeholder="ABC"/>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button  @click.prevent="updateProfile" v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2 mr-1" variant="primary">
            {{ $t('button.save') }}
          </b-button>

          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="mt-2"
              variant="outline-secondary"
              @click.prevent="redirectBack"
          >
            {{ $t('button.cancel') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import UserProfileAvatar from '@/modules/users/components/UserProfileAvatar';
import Ripple from 'vue-ripple-directive'
import { useUserApi } from '@/modules/users/composables/use-user-api';
import { useApplicationContext } from "@/shared/composables/use-application-context";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import router from '@/router'
import {useAgencyApi} from "@/modules/agency/composables/use-agency-api";
import {AUTH_STORE_NAMESPACE} from "@/modules/authnz/models/store";
import store from "@/store";
import roleConfig from '@/@core/data/roleConfig';

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    UserProfileAvatar

  },
  props: {
    memberData: {
      type: Object
    }
  },
  setup(props) {
    const { logger, route } = useApplicationContext();
    const {  updateUserProfile, deleteUserProfile } = useUserApi();
    const {displaySuccessMessage, displayErrorMessage} = useToastNotification();
    const {getAgency} = useAgencyApi();

    const pcVoyagesEnabled = store.state[AUTH_STORE_NAMESPACE].orgSettings.pcvoyages_enable;

    const updateProfile = async () => {
      try{
        let userId = route.value.params.id;
        await updateUserProfile(userId, props.memberData);
        displaySuccessMessage('Les changements ont été sauvegardés avec succès');
      } catch (e) {
        console.error(e)
        displayErrorMessage('Une erreur est survenue en essayant de sauvegarder les changements');
      }
    }


    const deleteMember = async () => {
      try{
        let userId = route.value.params.id;
        //await deleteUserProfile(userId, props.memberData);
        displaySuccessMessage('Les changements ont été sauvegardés avec succès');
      } catch (e) {
        console.error(e)
        displayErrorMessage('Une erreur est survenue en essayant de sauvegarder les changements');
      }
    }

    const redirectBack = () => {
      router.push({name:"agencies-edit"})
    }

    const roleOptions = roleConfig.roles.filter(role => role.value !== 'organization_admin' && role.value !== 'organization_coordinator');

    return {
      pcVoyagesEnabled,
      roleOptions,
      updateProfile,
      redirectBack,
      deleteMember
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';


</style>
